// Generated by Framer (abcfa95)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["fDu83V7JY"];

const serializationHash = "framer-u5yjx"

const variantClassNames = {fDu83V7JY: "framer-v-136y1ct"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "fDu83V7JY", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-136y1ct", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"fDu83V7JY"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7UG9wcGlucy1ib2xk", "--framer-font-family": "\"Poppins\", \"Poppins Placeholder\", sans-serif", "--framer-font-size": "30px", "--framer-font-weight": "700", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-a57779ef-e9c3-492f-a265-9667b33f96ed, rgb(0, 126, 198)))"}}>Butt</motion.p></React.Fragment>} className={"framer-1uht3bz"} fonts={["FS;Poppins-bold"]} layoutDependency={layoutDependency} layoutId={"fxb91k1iB"} style={{"--extracted-r6o4lv": "var(--token-a57779ef-e9c3-492f-a265-9667b33f96ed, rgb(0, 126, 198))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-u5yjx.framer-3kq54r, .framer-u5yjx .framer-3kq54r { display: block; }", ".framer-u5yjx.framer-136y1ct { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 91px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 413px; will-change: var(--framer-will-change-override, transform); }", ".framer-u5yjx .framer-1uht3bz { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-u5yjx.framer-136y1ct { gap: 0px; } .framer-u5yjx.framer-136y1ct > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-u5yjx.framer-136y1ct > :first-child { margin-top: 0px; } .framer-u5yjx.framer-136y1ct > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 91
 * @framerIntrinsicWidth 413
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWL6T2uezt: React.ComponentType<Props> = withCSS(Component, css, "framer-u5yjx") as typeof Component;
export default FramerWL6T2uezt;

FramerWL6T2uezt.displayName = "Buttom";

FramerWL6T2uezt.defaultProps = {height: 91, width: 413};

addFonts(FramerWL6T2uezt, [{explicitInter: true, fonts: [{family: "Poppins", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/EOHGWBZYYKO6R4PWP4S2B3FFWHHBEZN6/UWQLMF4AFWLXCJQCFV3WRVYC77KZXPRB/FYG6OCH7XOLUUSZTIZE65ATBZWF623O4.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})